export const combineClasses = (...classes) => {
    return classes.filter(it => it).join(" ");
};

const compareByKeys = (keys, a, b) => {
    if (keys.length === 0) {
        return 0;
    }
    const key = keys[0];
    if (a[key] === b[key]) {
        return compareByKeys(keys.slice(1), a, b);
    }
    if (a[key] < b[key]) {
        return -1;
    }
    return 1;
};

export const keyComparator = (...keys) => (a, b) => compareByKeys(keys, a, b);
