import React, {Component} from "react"
import styles from "./Grid.module.css"
import {combineClasses} from "../util/misc"

const convertTemplate = template => {
    if (typeof template === "number") {
        return Array.from(Array(template).keys()).map(() => "1fr").join(" ");
    }
    return template;
};

const convertGap = gap => {
    if (typeof gap === "number") {
        return gap + "px";
    }
    return gap;
};

export class GridContainer extends Component{

    static defaultProps = {
        rows: "auto",
        cols: "auto",
        flow: "row",
        align: "stretch", // start, end, center, stretch, space-around, space-between, space-evenly
        valign: "stretch",
        gap: "0px",
        alignContent: "stretch", // start, end, center, stretch
        valignContent: "stretch",
        className: "",
    };

    render() {
        const {children, className, style, rows, cols, flow, align, valign, gap, colGap, rowGap, alignContent, valignContent, ...other} = this.props;

        const finalStyle = {
            gridTemplateRows: convertTemplate(rows),
            gridTemplateColumns: convertTemplate(cols),
            gridAutoFlow: flow,
            justifyContent: align,
            alignContent: valign,
            gridColumnGap: convertGap(colGap || gap),
            gridRowGap: convertGap(rowGap || gap),
            justifyItems: alignContent,
            alignItems: valignContent,
            ...style
        };

        return <div className={combineClasses(styles.container, className)} style={finalStyle} {...other}>{children}</div>;
    }
}

export class GridCell extends Component {

    static defaultProps = {
        width: 1,
        height: 1,
        // align: "stretch",
        // valign: "stretch",
    };

    render() {
        const {children, className, style, display, row, col, width, height, align, valign, ...other} = this.props;

        const finalStyle = {
            display,
            gridColumnStart: col,
            gridColumnEnd: `span ${width}`,
            gridRowStart: row,
            gridRowEnd: `span ${height}`,
            justifySelf: align,
            alignSelf: valign,
            ...style
        };

        return <div className={combineClasses(styles.cell, className)} style={finalStyle} {...other}>{children}</div>;
    }
}
